import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import PatekCategoryBtns from '../../components/preowned/PatekCategoryBtns'

// markup
const PatekAnnualCalendar = () => {
  const data = useStaticQuery(
    graphql`
      query queryPatekPerpetualCalendar {
        products: allStrapiProduct(
          filter: {
            brand: { eq: "Patek Philippe" }
            model: { regex: "/Perpetual/" }
            qty: { gt: 0 }
          }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Patek Philippe Perpetual Calendar Watches for Sale'}
      canonical={'/fine-watches/patek-philippe/perpetual-calendar/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Patek Philippe Perpetual Calendar Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/patek-philippe/filter/model/perpetual-calendar/">
              <StaticImage
                src="../../images/preowned/preowned-patek-philippe-perpetual-calendar-banner.png"
                alt="Pre-Owned Certified Used Patek Philippe Perpetual Calendar Watches Header"
                aria-label="Used Patek Philippe Perpetual Calendar Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE PATEK PHILIPPE PERPETUAL
              CALENDAR WATCHES AT GRAY AND SONS
            </h1>
            <h2>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED PATEK PHILIPPE PERPETUAL CALENDAR
              WATCHES WITH GRAY AND SONS JEWELERS
            </h2>
            <p>
              {' '}
              Gray and Sons is a real watch and jewelry store located in Surfside/Miami. We are
              across from Bal Harbour Shops. We have real watch and jewelry experts on-site, open
              six days a week, we are not a virtual store. Our customers say we are #1 in Miami for
              used watches. We buy and sell and have been proudly assisting for the past 42 years.
              We're sellers and buyers of pre-owned, used and new Patek Philippe Perpetual Calendar
              watches in Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour,
              Surfside, Sunny Isles , Aventura, Fort Lauderdale and many more areas. We offer
              certified authentic and certfied pre-owned watches.
              <br />
              <br />
              Gray and Sons is the best place to go if you want to sell your Patek Philippe Watch.{' '}
              <a href="/">Www.grayandsons.com</a>is the best place to shop if you are in the market
              to buy a pre-owned Patek Philippe Watch too. Would you like to MAKE AN OFFER on
              watches and jewelry? Vika and Rich are live expert decision makers and ready to work
              with you now. A perpetual calendar is one of the most desirable complications in
              watches as it correctly indicates the day, date, month, leap year, and often the moon
              phase too, without the need for any adjusting on the part of the wearer. Many people
              say, I want to sell my Patek Philippe watch for cash. Grayandsons.com and
              sellusyourjewelry.com will buy your wristwatch for cash. Patek Philippe has had a long
              and storied history with perpetual calendar watches; it was the first watchmaker to
              create a perpetual calendar wristwatch in 1925.
              <br />
              <br />
              By 1941, Patek began serially producing perpetual calendar and perpetual calendar
              chronographs. Patek has continued to manufacture exquisite perpetual calendars and
              perpetual calendar chronographs since then, and these complicated timepieces are some
              of the most sought-after luxury watches in the collector’s market. Since the perpetual
              calendar has become a signature complication of the brand, it’s only natural that
              pre-owned Patek Perpetual Calendar watches and secondhand Patek Perpetual Calendar
              Chronographs are highly collectible. The secondary market has the benefit of not only
              offering current production references, but also discontinued and vintage Patek
              Philippe Perpetual Calendar references that you won’t find at retail boutiques.
              Pre-owned Patek Perpetual Calendars are available in a variety of materials (white
              gold, rose gold, yellow gold, and platinum, shapes (round or tonneau), and sizes.
              <br />
              <br />
              There are also secondhand Patek Perpetual Calendar models that run on manual-winding
              movements or automatic movements. Perpetual calendars are some of the most complicated
              watches that Patek Philippe makes. Therefore, it is essential that you only trust the
              care of a pre-owned Patek Perpetual Calendar watch to a specialized watchmaker. Lucky
              for you, the Gray &amp; Sons service facility is home to a team of expert watchmakers
              who are trained to repair or service used Patek Perpetual.
              <br />
              <br />
              Countless folks near me say, “I want to sell my Patek Philippe Perpetual Calendar
              watch for cash.” Gray and Sons and
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a> will buy your
              Patek Philippe Perpetual Calendar for cash. Gray and Sons is the best place to go for
              buying used Patek Philippe Perpetual Calendar watches, selling Patek Philippe
              Perpetual Calendar watches, and repairing Patek Philippe Perpetual Calendar watches.
              Even get your Patek Philippe Annual Calendar battery replaced here, at Gray and Sons.
              Our watches have been recently serviced, freshly lubricated, timing adjusted and
              detailed. Some of the Patek Philippe Perpetual Calendar watches that Gray and Sons
              recently sold include the Patek Philippe Perpetual in 18k yellow gold, 36 mm case
              size. Ref 3945/001J. Circa 1987. Another great Patek Philippe sale by Gray and Sons
              was Patek Philippe Perpetual Calendar in 18k on original alligator strap with 18k
              Calatrava Cross deployant buckle. Ref 5040. Circa 1994. Come into Gray and Sons to
              pick out your own Pre-Owned Patek Philippe Perpetual Calendar. Chat now with our real
              watch expert and decision makers. Rich and Vika will help you with any questions about
              purchasing and selling your Patek Philippe watch.
              <a href="/chat-with-rich"> Talk to a real person</a> here.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/patek-philippe/filter/model/perpetual-calendar/">
                <button>SHOP PATEK PHILIPPE PERPETUAL CALENDAR WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PATEK PHILIPPE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <PatekCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PatekAnnualCalendar
